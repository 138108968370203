












































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchCheckLoggedIn,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
} from "@/store/main/actions";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";

@Component({
  components: {},
})
export default class ScheduleWorkflow extends Vue {
  public building = "2";
  public mounted() {
    dispatchGetServiceAddress(this.$store, (response) => {
      this.building = _.get(response, "splynx_workflow_status_id", 2);
    });
  }
  hasProgress() {
    return !!this.building;
  }
}
