










































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import { dispatchCheckLoggedIn } from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import { readUserProfile } from "@/store/main/getters";
import Progress from "./Progress.vue";
import _ from "lodash";
import moment from "moment-timezone";
import ScheduleWorkflow from "./ScheduleWorkflow.vue";

@Component({
  components: { Header, Footer, Progress, ScheduleWorkflow },
})
export default class ScheduleSet extends Vue {
  public progress = "6";
  public async mounted() {
    await dispatchCheckLoggedIn(this.$store);
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }

  get date() {
    const schedule = _.get(this.userProfile, "installation_date", null);
    if (schedule) {
      return moment
        .utc(schedule)
        .tz("Asia/Manila")
        .format("MMM DD, YYYY HH:mm");
    }
    return "";
  }
}
